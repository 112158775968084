// extracted by mini-css-extract-plugin
export var address = "LocationTile__address__Utj30";
export var agreementCheck = "LocationTile__agreementCheck__pZ9mO";
export var anchor = "LocationTile__anchor__JVMT6";
export var badge = "LocationTile__badge__uF2HK";
export var column = "LocationTile__column__X6X9W";
export var details = "LocationTile__details__sXkB0";
export var detailsContainer = "LocationTile__detailsContainer__uy3D1";
export var flex = "LocationTile__flex__I2RDT";
export var flexColumn = "LocationTile__flexColumn__gZzY1";
export var gap1 = "LocationTile__gap1__l_rmL";
export var gap2 = "LocationTile__gap2__sgIA6";
export var gap3 = "LocationTile__gap3__P4WBN";
export var gap4 = "LocationTile__gap4__k2cnn";
export var gap5 = "LocationTile__gap5__fOfun";
export var image = "LocationTile__image__ylKp6";
export var listLocation = "LocationTile__listLocation__Q6ymU";
export var listLocationSummary = "LocationTile__listLocationSummary__bMyaE";
export var location = "LocationTile__location__tZKRS";
export var locationImage = "LocationTile__locationImage__LKWul";
export var logo = "LocationTile__logo__gcCnr";
export var name = "LocationTile__name__NrDKr";
export var photo = "LocationTile__photo__NP1l3";
export var productsInfo = "LocationTile__productsInfo__ilGp5";
export var provider = "LocationTile__provider__BSecG";
export var row = "LocationTile__row__PHfjW";
export var sponsored = "LocationTile__sponsored__NFHRk";
export var sponsoredLabel = "LocationTile__sponsoredLabel__Gwedo";