// extracted by mini-css-extract-plugin
export var breadcrumb = "Breadcrumb__breadcrumb__TR3Ce";
export var breadcrumbContainer = "Breadcrumb__breadcrumbContainer__qZzsR";
export var breadcrumbLink = "Breadcrumb__breadcrumbLink__IjnWv";
export var column = "Breadcrumb__column__9_54C";
export var flex = "Breadcrumb__flex__ggYBQ";
export var flexColumn = "Breadcrumb__flexColumn___M5zZ";
export var gap1 = "Breadcrumb__gap1__Wv9cG";
export var gap2 = "Breadcrumb__gap2__jG_q6";
export var gap3 = "Breadcrumb__gap3__hj6fp";
export var gap4 = "Breadcrumb__gap4__KTKcD";
export var gap5 = "Breadcrumb__gap5__rLpks";
export var row = "Breadcrumb__row___yB7b";